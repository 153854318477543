import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../theme";
import "./layout.css";
import NavBar from "./nav-bar/nav-bar";
import Footer from "./footer/footer";

const Layout = ({ children }) => (
  <>
    <ThemeProvider theme={theme}>
      <NavBar />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
