import styled from "styled-components";
import { Link } from "gatsby";

export const Nav = styled.nav`
  background: white;
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  transition: 0.5s all ease;
  box-shadow: 0px 0px 0px 2px #0001;

  @media screen and (max-width: 1080px) {
    background: #fff;
    height: 48px;
  }
`;

export const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 256px;
  text-align: center;
  align-items: center;
  margin-top: 0;

  @media screen and (max-width: 1600px) {
    padding: 0 128px;
  }

  @media screen and (max-width: 1300px) {
    padding: 0 64px;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 0;
  }

  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

export const NavLogo = styled(Link)`
  color: #141414;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: none;
  align-items: center;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;
export const NavIcon = styled.img`
  height: 100%;
`;

export const LogoText = styled.div`
  display: flex;
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.5rem;

  @media screen and (max-width: 1080px) {
    display: flex;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  z-index: 10;
  position: absolute;
  right: 24px;

  @media screen and (max-width: 1080px) {
    display: block;
    cursor: pointer;
    font-size: x-large;
  }
`;

export const NavMenu = styled.ul`
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: inherit;

    @media screen and (max-width: 1080px) {
        display: ${({ click, hiddenInMobile }) => (click && !hiddenInMobile ? "flex" : "none")};
        hiddenInMobile
        box-shadow: 0 0 2px 0 #000000;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        height: 100vh;
        top: ${({ click }) => (click ? 0 : "-1000px")};
        opacity: 1;
        transition: all 2s ease;
        background: white;
        right: 0;
        margin-top: 0;
        margin-right: 0;
        padding: 0
    }
`;

export const NavItem = styled.li`
  display: inline-block;
  margin: 0 12px;
  align-items: center;

  @media screen and (max-width: 1080px) {
    position: relative;
    top: 24px;
  }
`;

export const NavLink = styled(Link)`
  color: #141414;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.3em;
  white-space: nowrap;

  @media screen and (min-width: 1080px) {
    height: 80px;

    &:hover {
      color: #3a76aa;
    }
  }

  @media screen and (max-width: 1080px) {
    text-align: center;
    padding: 2rem;

    &:hover {
      color: #3a76aa;
    }
  }
`;
