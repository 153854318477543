import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";
import React from "react";
import { IconContext } from "react-icons/lib";
import {
  AdditionalContainer,
  FooterContainer,
  ColumnContainer,
  Column,
  InformationContainer,
  Separator,
} from "./footer-elements";
import ApprovalSeal from "../approval-seal/approval-seal";
import Border from "../border/border";
import ContentContainer from "../content-container/content-container";
import SocialMediaLink from "../social-media-link/social-media-link";
import { FACEBOOK } from "../social-media-link/social-media-types";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
  },
  text: {
    color: "white",
    padding: "16px 0",
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <IconContext.Provider value={{ size: "20", color: "#A3A3A3" }}>
        <FooterContainer>
          <ContentContainer>
            <ColumnContainer>
              <Column>
                <Typography variant="h5" component="h5" className={classes.text}>
                  Navigation
                </Typography>
                <Border />
                <InformationContainer>
                  <Link to="/" className={classes.link}>
                    <Typography variant="body1" component="p" className={classes.text}>
                      Home
                    </Typography>
                  </Link>
                  <Link to="/about-us" className={classes.link}>
                    <Typography variant="body1" component="p" className={classes.text}>
                      About Us
                    </Typography>
                  </Link>
                  <Link to="/moving" className={classes.link}>
                    <Typography variant="body1" component="p" className={classes.text}>
                      Services
                    </Typography>
                  </Link>
                  <Link to="/contact-us" className={classes.link}>
                    <Typography variant="body1" component="p" className={classes.text}>
                      Contact Us
                    </Typography>
                  </Link>
                </InformationContainer>
              </Column>

              <Column>
                <Typography variant="h5" component="h5" className={classes.text}>
                  Contact Information
                </Typography>
                <Border />
                <InformationContainer>
                  <Typography variant="body1" component="div" className={classes.text}>
                    Phone
                    <Typography variant="body2" component="div">
                      (214) 778-2352
                    </Typography>
                  </Typography>
                  <Typography variant="body1" component="div" className={classes.text}>
                    Email
                    <Typography variant="body2" component="div">
                      moveit@brotherbearmovers.com
                    </Typography>
                  </Typography>
                  <Typography variant="body1" component="div" className={classes.text}>
                    <SocialMediaLink text="Facebook" socialMediaType={FACEBOOK} />
                  </Typography>
                </InformationContainer>
              </Column>
              <Column>
                <Typography variant="h5" component="h5" className={classes.text}>
                  Locations
                </Typography>
                <Border />
                <InformationContainer>
                  <Typography variant="body1" component="p" className={classes.text}>
                    Dallas, Tx
                  </Typography>
                  <Typography variant="body1" component="p" className={classes.text}>
                    Austin, Tx
                  </Typography>
                </InformationContainer>
              </Column>
            </ColumnContainer>
            <Separator />
            <AdditionalContainer>
              <ApprovalSeal />
              <Typography variant="body1" component="p" className={classes.text}>
                © 2024 Brother Bear Moving Company
              </Typography>
            </AdditionalContainer>
          </ContentContainer>
        </FooterContainer>
      </IconContext.Provider>
    </>
  );
};

export default Footer;
