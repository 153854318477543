import styled from "styled-components";

export const Container = styled.div`
  padding: 16px 256px;
  text-align: ${({ isCentered }) => (isCentered ? "center" : "")};
  margin: ${({ isCentered }) => (isCentered ? "auto" : "0")};

  @media screen and (max-width: 1600px) {
    padding: 16px 128px;
  }

  @media screen and (max-width: 1300px) {
    padding: 16px 64px;
  }

  @media screen and (max-width: 800px) {
    padding: 16px 16px;
  }
`;

export default Container;
