import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FACEBOOK, links } from "./social-media-types";

const useStyles = makeStyles({
  container: {
    alignItems: "center",
    color: "lightGrey",
    cursor: "pointer",
    display: "flex",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
  },
  text: {
    paddingLeft: "8px",
  },
});

const SocialMediaLink = ({ socialMediaType, text }) => {
  const classes = useStyles();

  const renderSymbol = (type) => {
    switch (type) {
      case FACEBOOK:
        return <FaFacebook />;
      default:
        return <></>;
    }
  };

  const openNewTab = (e, url) => {
    e.preventDefault();
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div
      className={classes.container}
      onClick={(e) => {
        openNewTab(e, links[socialMediaType]);
      }}
      onKeyDown={(e) => {
        openNewTab(e, links[socialMediaType]);
      }}
      role="button"
      tabIndex={0}
    >
      {renderSymbol(socialMediaType)}
      <span className={classes.text}>{text}</span>
    </div>
  );
};

export default SocialMediaLink;
