import React from "react";
import PropTypes from "prop-types";
import { Container } from "./content-container-elements";

const ContentContainer = ({ isCentered, children }) => (
  <>
    <Container isCentered={isCentered}>{children}</Container>
  </>
);

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentContainer;
