import { createMuiTheme } from "@material-ui/core/styles";

require("typeface-montserrat");

const theme = createMuiTheme({
  typography: {
    body1: {
      fontSize: "1rem",
      lineHeight: "32px",
    },
    fontFamily: "'Montserrat', sans-serif",
    h1: {
      fontSize: "2.9rem",
      fontWeight: 600,
      lineHeight: 1.2,
      marginBottom: 32,
    },
    h2: {
      fontSize: "1.8125rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.3125rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.1875rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1.125rem",
      fontWeight: "bold",
    },
    subtitle1: {
      fontSize: "1.125rem",
      lineHeight: "32px",
      fontWeight: 500,
    },
  },
});

export default theme;
