import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavBarContainer,
  NavLogo,
  NavIcon,
  LogoText,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
} from "./nav-bar-elements";
import logo from "../../images/logo.png";
import SocialMediaLink from "../social-media-link/social-media-link";
import { FACEBOOK } from "../social-media-link/social-media-types";

const NavBar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <IconContext.Provider value={{ strokeWidth: "1px" }}>
        <Nav click={click}>
          <NavBarContainer>
            <NavLogo to="/">
              <NavIcon src={logo} />
            </NavLogo>
            <NavLink to="/">
              <LogoText>Brother Bear Moving Co.</LogoText>
            </NavLink>
            <MobileIcon onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</MobileIcon>
            <NavMenu click={click}>
              <NavItem>
                <NavLink to="/" activeClassName="active">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/about-us" activeClassName="active">
                  About Us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/moving" activeClassName="active">
                  Moving
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/contact-us" activeClassName="active">
                  Contact Us
                </NavLink>
              </NavItem>
            </NavMenu>
            <NavMenu hiddenInMobile>
              <NavItem>
                <NavLink to="">
                  <SocialMediaLink socialMediaType={FACEBOOK} />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="">(214) 778-2352</NavLink>
              </NavItem>
            </NavMenu>
          </NavBarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default NavBar;
