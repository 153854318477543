import styled from "styled-components";

export const AdditionalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  text-align: center;
`;

export const FooterContainer = styled.div`
  background: #000639;
  bottom: 0;
  position: absolute;
  width: 100%;
`;

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InformationContainer = styled.div`
  padding: 8px 0;
`;

export const Separator = styled.div`
  width: 50%;
  margin: auto;
  padding: 24px 64px;
  border-bottom: 1px solid white;
`;
