import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  block: {
    display: "block",
    margin: "auto",
    width: 160,
  },
  image: {
    display: "block",
  },
});

const ApprovalSeal = () => {
  const classes = useStyles();

  return (
    <>
      <a
        href="https://www.homeadvisor.com/rated.CarterOnlinetraining.70720132.html"
        className={classes.block}
      >
        <img
          alt="Brother Bear Group, LLC is a HomeAdvisor Screened & Approved Pro"
          className={classes.image}
          src="https://www.homeadvisor.com/images/sp-badges/soap-solid-border.png?sp=70720132&key=6f21565b6101ead010925a8a0e8649e2"
        />
      </a>
    </>
  );
};

export default ApprovalSeal;
