import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  border: {
    borderTop: "3px solid #51b53f",
    marginBottom: "16px",
    marginTop: "24px",
    width: "48px",
  },
});

const Border = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.border} />
    </>
  );
};

export default Border;
